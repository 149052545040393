import React from "react"
import styled from "styled-components"
import PartnerImg from "./partnerImg.svg"
import AfyaLogo from "../../images/store.png"
const Container = styled.div`
  width: 100%;
`
const Header = styled.div`
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 50px 0 0 0;
`
const Heading = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 53px;

  color: #171717;
`
const ImgWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`
const Partner = styled.img`
  width: 150px;
  height: 150px;
  margin: 0 20px;
`
export default function Partners() {
  return (
    <>
      <Container>
        <Header>
          <Heading>Trusted Partners</Heading>
        </Header>
        <ImgWrapper>
        <div style={{display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center"}}>
          <Partner src={AfyaLogo} />
          <h3>Glanver</h3>
          </div>
          <div style={{display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center"}}>
          <Partner src={AfyaLogo} />
          <h3>Northcross</h3>
          </div>
          <div style={{display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center"}}>
          <Partner src={AfyaLogo} />
          <h3>Absolute</h3>
          </div>
          <div style={{display:"flex", flexDirection:"column",alignItems:"center", justifyContent:"center"}}>
          <Partner src={AfyaLogo} />
          <h3>Pharmilymed</h3>
          </div>
        </ImgWrapper>
      </Container>
    </>
  )
}
