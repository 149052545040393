import styled from 'styled-components';

export const WhyContainer = styled.div`
  font-family: oswald;
`;
export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 100px 150px;
  @media screen and (min-width: 320px) {
    margin: 3em 0;
    flex-direction: column;
  }
  @media screen and (min-width: 425px) {
    margin: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-evenly;
  }
  @media screen and (min-width: 1024px) {
    margin: 3em;
    /* margin: 5em; */
    flex-direction: row;
    justify-content: space-evenly;
  }
  @media screen and (min-width: 1440px) {
    margin: 5em;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

export const WrapperAd = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin: 100px 150px;
  @media screen and (min-width: 320px) {
    margin: 3em 0;
    flex-direction: column;
  }
  @media screen and (min-width: 425px) {
    margin: 1em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  @media screen and (min-width: 1024px) {
    margin: 3em;
    flex-direction: row;
    justify-content: space-around;
  }
  @media screen and (min-width: 1440px) {
    margin: 5em;
    flex-direction: row;
    justify-content: space-around;
  }
`;

export const ImgCont = styled.div`
  @media screen and (min-width: 320px) {
    width: 100vw;
    padding: 1em;
  }
  @media screen and (min-width: 425px) {
    width: 25em;
    margin: 2em 0;
  }
  @media screen and (min-width: 768px) {
    width: 80%;
  }
  @media screen and (min-width: 1024px) {
    width: 70%;
  }
  @media screen and (min-width: 1440px) {
    width: 45em;
  }
`;
export const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;
export const TextCont = styled.div`
  margin: 0 0 0 5em;
  @media screen and (min-width: 320px) {
    margin: 3em 1.5em 0;
  }
  @media screen and (min-width: 425px) {
    margin: 3em 0;
  }
  @media screen and (min-width: 768px) {
    margin: 3em 0 0 5em;
  }
  @media screen and (min-width: 1024px) {
    margin: 3em 0 0 5em;
  }
  @media screen and (min-width: 1440px) {
    margin: 3em 0 0 5em;
  }
`;
export const Heading = styled.h3`
  font-size: 5em;
  font-weight: 400;
  @media screen and (min-width: 320px) {
    font-size: 3em;
  }
  @media screen and (min-width: 425px) {
    font-size: 3em;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 4em;
  }
`;
export const PointUl = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: 20px;
`;
export const Points = styled.li`
  list-style-type: none;
  display: flex;
  margin-top: 30px;
  @media screen and (min-width: 320px) {
    display: flex;
    align-items: center;
  }
  @media screen and (min-width: 768px) {
    margin: 0;
  }
`;
export const PointIcon = styled.span`
  svg {
    color: #1d9682;
    height: 20px;
    width: 20px;
    margin-right: 15px;
  }
`;
export const PointText = styled.p`
  font-size: 20px;
  font-weight: 300;
  margin: 0;
  padding: 0;
  @media screen and (min-width: 768px) {
    font-size: 1.3em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.4em;
  }
  @media screen and (min-width: 1440px) {
    font-size: 1.7em;
  }
`;
export const BottomArrow = styled.div``;
export const ArrowImg = styled.img``;
