import styled from 'styled-components';
import { mobileM, mobileS, desktop } from '../../responsive';
import ServBg from './serv.svg';

export const ServContainer = styled.div`
  padding: 100px;
  display: flex;
 flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${ServBg});
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    @media screen and (min-width: 320px) {
    padding: 0;
    width: 100%;
  }
  @media screen and (min-width: 425px) {
    padding: 1em;
  }
`;

export const ServWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 94%;
  flex-wrap: wrap;
  
  @media screen and (min-width: 320px) {
    width: 100%;
    padding: 1em;
  }
    
  @media screen and (min-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
    
`;
export const Header = styled.div`
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 0;
  @media screen and (min-width: 1440px) {
    padding: 6em 0 0;
  }
`;
export const ServHeader = styled.h1`
  font-family: Oswald;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 53px;
  color: #171717;
  ${mobileS({
    fontWeight: '400',
    fontSize: '26px',
    lineHeight: '25px',
  })}
  ${mobileM({
    fontWeight: '500',
    fontSize: '35px',
    lineHeight: '25px',
  })}
  ${desktop({
    fontWeight: 'bold',
    fontSize: '40px',
  })}
`;
