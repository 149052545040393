import styled from 'styled-components';

export const Container = styled.div`
  background-color: white;
`;
export const Wrapper = styled.div`
  margin: 10px 150px 30px;
  font-family: oswald;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: 320px) {
    margin: 0;
  }
  @media screen and (min-width: 425px) {
    margin: 0;
  }
`;
export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px;
  @media screen and (min-width: 320px) {
    padding: 2em;
  }
`;
export const Title = styled.h1`
  font-size: 3em;
`;
export const Slogan = styled.h4``;
export const InnerCont = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-top: 2px dashed #1d9682;
  padding: 20px;
  @media screen and (min-width: 320px) {
    width: 80%;
  }
`;
export const Card = styled.div`
  margin: 30px;
  width: 30em;
  //height: 24em;
  height: 100%;
  padding: 40px;
  border-left: 2px solid #1d9682;
  border-bottom: 2px solid #1d9682;
  @media screen and (min-width: 320px) {
    margin: 1em;
    padding: 1em;
  }
`;
export const IconSpan = styled.span`
  svg {
    width: 5em;
    height: 5em;
    color: #1d9682;
  }
`;
export const CardTitle = styled.p`
  font-size: 2em;
  font-weight: 400;
`;
export const CardDesc = styled.p`
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
  @media screen and (min-width: 1440px) {
    font-size: 1.6em;
  }
  @media screen and (min-width: 1024px) {
    font-size: 1.6em;
  }
  @media screen and (min-width: 768px) {
    font-size: 1.6em;
  }
  @media screen and (min-width: 425px) {
    font-size: 1.6em;
  }
`;
