import React from 'react';
import { IoIosCheckmarkCircle } from 'react-icons/io';

export const data = [
  {
    Title: 'Why Now',
  },
  {
    id: 1,
    icon: <IoIosCheckmarkCircle />,
    point: 'A surge in internet searches for pharmaceutical products ',
  },
  {
    id: 2,
    icon: <IoIosCheckmarkCircle />,
    point: 'Begin building a loyal customer base and online reviews ',
  },
  {
    id: 3,
    icon: <IoIosCheckmarkCircle />,
    point: 'Patients now expect consumer-like customer experience as amazon',
  },
  {
    id: 4,
    icon: <IoIosCheckmarkCircle />,
    point: 'Clinical and business productivity will benefit from data insights',
  },
];

export const PharmMgtSys = [
  {
    Title: 'Pharmacy Management System',
  },
  {
    id: 1,
    icon: <IoIosCheckmarkCircle />,
    point: 'Process sales whether walk-in or online and receive payments ',
  },
  {
    id: 2,
    icon: <IoIosCheckmarkCircle />,
    point: 'Begin building a loyal customer base and online reviews ',
  },
  {
    id: 3,
    icon: <IoIosCheckmarkCircle />,
    point: 'Monitor and manage your stock inventory and staff',
  },
  {
    id: 4,
    icon: <IoIosCheckmarkCircle />,
    point: 'Increase patient engagement through loyalty programs ',
  },
  {
    id: 5,
    icon: <IoIosCheckmarkCircle />,
    point: 'Optimize treatments through MTM and Remote patient monitoring ',
  },
  {
    id: 6,
    icon: <IoIosCheckmarkCircle />,
    point: 'Data analytics provide real-time insights into your business ',
  },
  {
    id: 7,
    icon: <IoIosCheckmarkCircle />,
    point:
      'Utilize our short expiry redistribution network to avoid expiry losses. ',
  },
];

export const dataClinic = [
  {
    Title: 'Why Now',
  },
  {
    id: 1,
    icon: <IoIosCheckmarkCircle />,
    point: 'A surge in internet searches for medical conditions',
  },
  {
    id: 2,
    icon: <IoIosCheckmarkCircle />,
    point: 'Begin building a loyal customer base and online reviews',
  },
  {
    id: 3,
    icon: <IoIosCheckmarkCircle />,
    point: 'Patients now expect consumer-like customer experience as other sectors',
  },
  {
    id: 4,
    icon: <IoIosCheckmarkCircle />,
    point: 'Clinical and business productivity will benefit from data insights',
  },
];
