import React from "react"
import {
  Heading,
  ImgCont,
  PointIcon,
  Points,
  PointText,
  PointUl,
  TextCont,
  WhyContainer,
  Wrapper,
} from "./styles/whyNow"
import { StaticImage } from "gatsby-plugin-image"

export default function whyUs({ data }) {
  return (
    <WhyContainer>
      <Wrapper>
        <ImgCont>
          <StaticImage height={400} src="../../images/pharmWhy.jpg" alt="Business profit" placeholder="blurred" style={{borderRadius: '10%'}}/>
        </ImgCont>
        <TextCont>
          {data.map(item => {
            return <Heading>{item.Title}</Heading>
          })}

          <PointUl>
            {data.map(item => {
              return (
                <Points key={item.id}>
                  <PointIcon>{item.icon}</PointIcon>
                  <PointText>{item.point}</PointText>
                </Points>
              )
            })}
          </PointUl>
        </TextCont>
      </Wrapper>
    </WhyContainer>
  )
}
