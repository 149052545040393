import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "How do I join Afyabook",
    body: "Send us an email to support@patameds.com and our representative will get in touch with on boarding details ",
  },
  {
    id: 1,
    title: "Do I pay anything to be on Afyabook?",
    body: "Listing on Afyabook is free though you need to subscribe to access our back office modules, analytics and receive online orders. ",
  },
  {
    id: 3,
    title: "I have an online platform already; do I need to join Afyabook",
    body: "Yes, in the internet world getting traffic from a variety of sources gives you a competitive advantage",
  },
  {
    id: 4,
    title: "What other benefits do I get through Afyabook",
    body: "Access to credit facilities. Business consulting and support. Pooled purchase benefits, reduces cost. A data intensive platform for insight discovery",
  },
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions</h1>
          <h3 className="subheading">Some frequent questions clients ask</h3>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
